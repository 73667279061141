var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { fontFamily: _vm.fontFamily } },
    [
      _c("ckeditor", {
        attrs: {
          disabled: _vm.disabled,
          editor: _vm.editor,
          config: _vm.editorConfig,
          value: _vm.content
        },
        on: { ready: _vm.onReady, input: _vm.handleChange }
      }),
      _vm.isWarning
        ? _c(
            "div",
            { staticClass: "note-text-style" },
            [
              _c("a-icon", {
                attrs: { type: "exclamation-circle", theme: "filled" }
              }),
              _vm._v(" " + _vm._s(_vm.warning.message) + " ")
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }