//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import { v4 as uuidv4 } from 'uuid';
import ClassicEditor from '@blowstack/ckeditor5-full-free-build';
import '@blowstack/ckeditor5-full-free-build/build/translations/ja';
import { uploadFileToS3 } from '@/api/s3';
import { FONT_FAMILY_DEFAULT_EDITOR } from '@/enum';

const devicesDimensionElement = (width, limitationHeight) => {
  (width = width || 310), (limitationHeight = limitationHeight || 1250);
  const that = {
    width,
    limitationHeight,
    element: null
  };
  return {
    width: width || 310,
    limitationHeight: limitationHeight || 1277,
    element: null,
    getElement: () => {
      if (that.element) return that.element;
      const div = document.createElement('div');
      div.style.width = width + 'px';
      div.style.position = 'absolute';
      div.style.top = '-9999px';
      div.style.left = '-9999px';
      div.style.visibility = 'hidden';
      div.style.display = 'block';
      div.className =
        'ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline ck-blurred';
      that.element = div;
      return that.element;
    }
  };
};
const devicesDimension = {
  name: 'Huawei nova 3i',
  dimension: devicesDimensionElement(310, 1250)
};
// {
//   name: 'Galaxy A8',
//   dimension: devicesDimensionElement(310, 1250)
// }

// eslint-disable-next-line
let type = '';
let projectId = 0;
class CustomUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }
  async getBase64FromImageFile(file) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  }
  async upload() {
    const file = await this.loader.file;
    const key = `${process.env.VUE_APP_S3_HP}/${projectId}/${uuidv4()}_${file.name}`;
    const url = await uploadFileToS3({ key, contentType: file.type, file });
    return { default: url };
  }
  abort() { }
}

export default {
  components: { ckeditor: CKEditor.component },
  props: {
    content: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    projectId: { type: String, default: '' },
    projectLang: { type: String, default: 'jpn' },
    type: { type: String, default: '' },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      editor: ClassicEditor,
      warning: {
        errors: [],
        message: ''
      }
    };
  },

  computed: {
    ...mapGetters(['currentLanguage']),
    fontFamily() {
      let existLang = Object.keys(FONT_FAMILY_DEFAULT_EDITOR);
      let usedLang = existLang.includes(this.projectLang) ? this.projectLang : 'eng';
      return FONT_FAMILY_DEFAULT_EDITOR[usedLang];
    },
    editorConfig() {
      return {
        language: { ui: this.currentLanguage },
        placeholder: this.placeholder,
        removePlugins: [
          'Title',
          'MediaEmbed',
          'MediaEmbedToolbar',
          'SimpleUploadAdapter',
          'CKFinder',
          'EasyImage',
          'ImageCaption'
        ],
        extraPlugins: [this.MyCustomUploadAdapterPlugin],
        // full toolbar:
        // { "items": [ "undo", "redo", "removeFormat", "heading", "|", "fontSize", "bold", "italic", "underline", "strikeThrough", "fontFamily", "fontColor", "fontBackgroundColor", "|", "link", "highlight", "code", "codeBlock", "blockQuote", "superscript", "subscript", "specialCharacters", "|", "alignment", "indent", "outdent", "pageBreak", "|", "bulletedList", "numberedList", "todoList", "|", "imageUpload", "insertTable", "horizontalLine", "MathType", "ChemType" ] }
        toolbar: [
          'undo',
          'redo',
          'removeFormat',
          'heading',
          '|',
          'fontSize',
          'bold',
          'italic',
          'fontFamily',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'link',
          'highlight',
          '|',
          'alignment',
          'indent',
          'outdent',
          '|',
          'bulletedList',
          'numberedList',
          '|',
          'imageUpload',
          'horizontalLine'
        ],
        fontFamily: {
          options: [
            { title: this.fontFamily, model: undefined },
            // 'default',
            'Arial, Helvetica, sans-serif',
            'Courier New, Courier, monospace',
            'Georgia, serif',
            'Lucida Sans Unicode, Lucida Grande, sans-serif',
            'Tahoma, Geneva, sans-serif',
            'Times New Roman, Times, serif',
            'Trebuchet MS, Helvetica, sans-serif',
            'Verdana, Geneva, sans-serif'
          ],
          supportAllValues: true
        },
        fontSize: {
          options: [
            {
              title: '6',
              model: '6px'
            },
            {
              title: '9',
              model: '9px'
            },
            {
              title: '12',
              model: '12px'
            },
            {
              title: '14',
              model: '14px'
            },
            {
              title: '16',
              model: '16px'
            },
            {
              title: '18',
              model: '18px'
            },
            {
              title: '20',
              model: '20px'
            },
            {
              title: '24',
              model: '24px'
            },
            {
              title: '30',
              model: '30px'
            },
            {
              title: '36',
              model: '36px'
            },
            {
              title: '48',
              model: '48px'
            }
          ]
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3' },
            { model: 'heading4', view: 'h4', title: 'Heading 4' },
            { model: 'heading5', view: 'h5', title: 'Heading 5' },
            { model: 'heading6', view: 'h6', title: 'Heading 6' }
          ]
        },
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableProperties',
            'tableCellProperties'
          ]
        },
        image: {
          // styles: [
          //   'alignLeft',
          //   'alignCenter',
          //   'alignRight'
          // ],
          resizeOptions: [
            {
              name: 'resizeImage:original',
              label: 'Original',
              value: null
            },
            {
              name: 'resizeImage:25',
              label: '25%',
              value: '25'
            },
            {
              name: 'resizeImage:50',
              label: '50%',
              value: '50'
            },
            {
              name: 'resizeImage:75',
              label: '75%',
              value: '75'
            }
          ],
          toolbar: [
            'imageStyle:alignLeft',
            'imageStyle:alignCenter',
            'imageStyle:alignRight',
            '|',
            'resizeImage',
            'imageTextAlternative'
          ],
          types: ['jpeg', 'png', 'gif', 'bmp', 'webp', 'tiff']
        }
      };
    },
    isWarning() {
      return this.warning.errors.length > 0;
    }
  },
  created() {
    type = this.type;
    projectId = this.projectId;
    this.handleCheckDevicesDimensionLimitation(this.content);
  },
  methods: {
    onReady(editor) {
      editor.conversion.for('downcast').add(dispatcher => {
        const models = [
          'paragraph',
          'heading1',
          'heading2',
          'heading3',
          'heading4',
          'heading5',
          'image'
        ];
        models.forEach(model => {
          dispatcher.on(`insert:${model}`, (evt, data, conversionApi) => {
            if (model === 'image') {
              return this.insertAttImage(data.item, conversionApi);
            }
            this.insertAttParagraph(data.item, conversionApi);
            evt.stop();
          });
        });
        dispatcher.on(`insert:table`, (evt, data, conversionApi) => {
          this.insertAttTable(data.item, conversionApi);
          evt.stop();
        });
        dispatcher.on(`insert:listItem`, (evt, data, conversionApi) => {
          this.insertAttListItem(data.item, conversionApi);
          evt.stop();
        });
      });
      editor.conversion.for('downcast').attributeToElement({
        model: 'italic',
        view: 'em',
        converterPriority: 'high'
      });
    },
    MyCustomUploadAdapterPlugin(editor) {
      editor.plugins.get('FileRepository').createUploadAdapter = loader => {
        return new CustomUploadAdapter(loader);
      };
    },
    insertAttTable(node, conversionApi) {
      if (node.name === 'tableCell') {
        const { writer, mapper } = conversionApi;
        writer.setAttribute('tabindex', '0', mapper.toViewElement(node));
      } else if (node._children && node._children._nodes?.length > 0) {
        node._children._nodes.forEach(nodeItem => {
          this.insertAttTable(nodeItem, conversionApi);
        });
      }
    },
    insertAttParagraph(node, conversionApi) {
      if (node?._data?.trim()) {
        const { writer, mapper } = conversionApi;
        writer.setAttribute('tabindex', '0', mapper.toViewElement(node.parent));
      } else if (node._children && node._children._nodes?.length > 0) {
        node._children._nodes.forEach(nodeItem => {
          this.insertAttParagraph(nodeItem, conversionApi);
        });
      }
    },
    insertAttImage(node, conversionApi) {
      const { writer, mapper } = conversionApi;
      const currentAttrs = node._attrs;
      let existALT = 0;
      for (let i = 0; i < currentAttrs.length; i++) if (currentAttrs[i].key === 'alt') existALT = 1;
      if (!existALT) writer.setAttribute('alt', '', mapper.toViewElement(node)._children[0]);
      Object.assign(node._attrs, currentAttrs);
      writer.setAttribute('tabindex', '0', mapper.toViewElement(node)._children[0]);
    },
    insertAttListItem(node, conversionApi) {
      const { writer, mapper } = conversionApi;
      writer.setAttribute('tabindex', '0', mapper.toViewElement(node));
    },

    handleCheckDevicesDimensionLimitation(value) {
      // simulate a html dom element via devicesDimensionto calculate the height of the content inside the editor
      const element = devicesDimension.dimension.getElement();
      element.innerHTML = value;
      document.body.appendChild(element);
      if (element.offsetHeight > devicesDimension.dimension.limitationHeight) {
        // push if device.name is not in this.warning.errors
        if (!this.warning.errors.includes(devicesDimension.name))
          this.warning.errors.push(devicesDimension.name);
        // throw message which device is over the limitation height of the content
        this.warning.message = this.$t(
          'Over the limited height of header or footer, which maybe makes the content not readable on some older devices, or even crash the app. Please reduce the contents.'
        );
      } else {
        this.warning.errors = this.warning.errors.filter(item => item !== devicesDimension.name);
      }
      document.body.removeChild(element);
    },
    handleChange(value) {
      this.handleCheckDevicesDimensionLimitation(value);
      this.$emit('change', value);
    }
  }
};
